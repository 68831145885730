import { Button, Stack, Typography } from "@mui/material";
import React from "react";
// import IMAGES from "../../assets/images.ts";
import page1 from "./page1.jpg";
import page2 from "./page2.jpg";

function Home() {
  return (
    <div style={{ marginTop: "1%", paddingInline: "1%" }}>
      <Typography variant="h4" textAlign={"center"} fontFamily={"fantasy"}>
        Pahadi Rasoi Ghar
      </Typography>
      <Stack
        direction={"Row"}
        justifyContent={"space-around"}
        gap={50}
        alignItems={"center"}
      >
        <Stack direction={"Row"} gap={5}>
          {/* <Stack> */}
          <Typography>Home</Typography>
          {/* </Stack> */}
          {/* <Stack> */}
          <Typography>About</Typography>
          {/* </Stack> */}
          {/* <Stack> */}
          <Typography>Menu</Typography>
          {/* </Stack>
          <Stack> */}
          <Typography>Prices</Typography>
          {/* </Stack> */}
        </Stack>
        {/* <Button variant="outlined" color="inherit">
          Food Subscription Available
        </Button> */}
      </Stack>

      <Stack alignItems={"center"} spacing={2} marginTop={10}>
        <Typography variant="h1" textAlign={"center"} fontFamily={"cursive"}>
          Welcome
        </Typography>
        <Typography variant="body1" textAlign={"center"} fontFamily={"cursive"}>
          Food for the soul and body. Just like Home!
        </Typography>
        <Button variant="contained" color="secondary">
          Contact to Order
        </Button>
      </Stack>
      {/* <Stack direction={"row"} justifyContent={"space-between"} marginTop={20}>
        <Stack border={"1px solid black"}>1</Stack>
        <Stack border={"1px solid black"}>2</Stack>
      </Stack> */}
      <Stack marginTop={5} spacing={1}>
        <img src={page1} alt="Menu" />
        <img src={page2} alt="Menu" />
      </Stack>
    </div>
  );
}

export default Home;
